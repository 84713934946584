export const IS_PROD = process.env.NEXT_PUBLIC_APP_STAGE === "production";
export const REVALIDATION_TIME = IS_PROD ? 60 * 60 * 24 * 2 : 60 * 30; // 2 days in production, 30 minutes in development
export const REVALIDATION_MATCHUP_TIME = IS_PROD ? 60 * 60 : 60 * 30; // 1 hour in production, 30 minutes in development
export const ABOUT_US_PATH = "/about-us/";
export const STORE_PATH = "https://betting-news.printify.me/products";
export const NEWSLETTER_PATH = "/newsletter/";
export const SPORTS_BETTING_SHOWS = "/sports-betting-shows/";
export const RESPONSIBLE_GAMING = "/responsible-gaming-what-you-need-to-know/ ";

export const BETTING_RECORD_HEADINGS = {
  team: "Team",
  ats: "ATS",
  atsHome: "ATS Home",
  atsAway: "ATS Away",
  overUnder: "Over / Under",
};

export const DEFAULT_AWAY_TEAM_COLOR = "rgb(71, 163, 243)";
export const DEFAULT_HOME_TEAM_COLOR = "rgb(245, 74, 85)";

export const HERO_CARD_GRID_NUMBER_OF_INITIAL_VISIBLE_ITEMS = 11;
export const HERO_CARD_GRID_NUMBER_OF_ITEMS_TO_INCREMENT = 10;
export const HOMEPAGE_HERO_CARD_GRID_NUMBER_OF_INITIAL_VISIBLE_ITEMS = 28;

export const LEAGUE_GROUPINGS = {
  mlb: {
    division: {
      ["American League East"]: {
        title: "American League East",
        standings: [],
      },
      ["American League Central"]: {
        title: "American League Central",
        standings: [],
      },
      ["American League West"]: {
        title: "American League West",
        standings: [],
      },
      ["National League East"]: {
        title: "National League East",
        standings: [],
      },
      ["National League Central"]: {
        title: "National League Central",
        standings: [],
      },
      ["National League West"]: {
        title: "National League West",
        standings: [],
      },
    },
    league: {
      ["American League"]: { title: "American League", standings: [] },
      ["National League"]: { title: "National League", standings: [] },
    },
  },
  nba: {
    conference: {
      Eastern: { title: "Eastern", standings: [] },
      Western: { title: "Western", standings: [] },
    },
    division: {
      Atlantic: { title: "Atlantic", standings: [] },
      Central: { title: "Central", standings: [] },
      Southeast: { title: "Southeast", standings: [] },
      Northwest: { title: "Northwest", standings: [] },
      Pacific: { title: "Pacific", standings: [] },
      Southwest: { title: "Southwest", standings: [] },
    },
  },
  wnba: {
    conference: {
      Eastern: { title: "Eastern", standings: [] },
      Western: { title: "Western", standings: [] },
    },
    division: {},
  },
  nfl: {
    conference: {
      NFC: { title: "NFC", standings: [] },
      AFC: { title: "AFC", standings: [] },
    },
    division: {
      ["NFC - East"]: { title: "NFC - East", standings: [] },
      ["NFC - West"]: { title: "NFC - West", standings: [] },
      ["NFC - North"]: { title: "NFC - North", standings: [] },
      ["NFC - South"]: { title: "NFC - South", standings: [] },
      ["AFC - East"]: { title: "AFC - East", standings: [] },
      ["AFC - West"]: { title: "AFC - West", standings: [] },
      ["AFC - North"]: { title: "AFC - North", standings: [] },
      ["AFC - South"]: { title: "AFC - South", standings: [] },
    },
  },
  nhl: {
    conference: {
      Eastern: { title: "Eastern", standings: [] },
      Western: { title: "Western", standings: [] },
    },
    division: {
      Atlantic: { title: "Atlantic", standings: [] },
      Metropolitan: { title: "Metropolitan", standings: [] },
      Central: { title: "Central", standings: [] },
      Pacific: { title: "Pacific", standings: [] },
    },
  },
};

export const LEAGUE_PAGES = [
  "nfl",
  "nba",
  "wnba",
  "nhl",
  "mlb",
  "ncaaf",
  "ncaab",
  "soccer",
  "wnba",
];

export const COLLEGE_LEAGUES = ["ncaaf", "ncaab"];

export const SOCCER_LEAGUES = [
  "english-premier-league",
  "mls",
  "liga-mx",
  "uefa-champions-league",
];

export const SOCCER_NEWS = [
  "english-premier-league",
  "epl",
  "mls",
  "mlmx",
  "uefa-champions-league",
];

export const MATCHUP_TRENDS_HEADERS = ["Result", "ATS", "Total"];

export const MLB_STANDINGS_HEADINGS = {
  team: "Team",
  gamesPlayed: "GP",
  wins: "W",
  losses: "L",
  gamesBack: "GB",
  winPercentage: "W%",
  runsFor: "RF",
  runsAgainst: "RA",
  runsDifference: "DIFF",
  homeRecord: "HOME",
  awayRecord: "AWAY",
};

export const NFL_STANDINGS_HEADINGS = {
  team: "Team",
  gamesPlayed: "GP",
  wins: "W",
  losses: "L",
  winPercentage: "W%",
  pointsFor: "PF",
  pointsAgainst: "PA",
  pointsDifference: "DIFF",
  homeRecord: "HOME",
  awayRecord: "AWAY",
};

export const NCAAF_STANDINGS_HEADINGS = {
  team: "Team",
  gamesPlayed: "GP",
  wins: "W",
  losses: "L",
  winPercentage: "W%",
  pointsFor: "PF",
  pointsAgainst: "PA",
  pointsDifference: "DIFF",
  homeRecord: "HOME",
  awayRecord: "AWAY",
};

export const NBA_STANDINGS_HEADINGS = {
  team: "Team",
  gamesPlayed: "GP",
  wins: "W",
  losses: "L",
  gamesBack: "GB",
  winPercentage: "W%",
  pointsFor: "PF",
  pointsAgainst: "PA",
  pointsDifference: "DIFF",
  homeRecord: "HOME",
  awayRecord: "AWAY",
};

export const NHL_STANDINGS_HEADINGS = {
  team: "Team",
  gamesPlayed: "GP",
  wins: "W",
  losses: "L",
  overtimeLosses: "OT",
  points: "PTS",
  winPercentage: "P%",
  goalsFor: "GF",
  goalsAgainst: "GA",
  goalsDifference: "DIFF",
  homeRecord: "HOME",
  awayRecord: "AWAY",
};

export const NAV_LOGO_SMALL =
  "https://bettingnewscontent.s3.us-east-2.amazonaws.com/bn-icon.png";
export const NAV_LOGO_LARGE =
  "https://bettingnewscontent.s3.us-east-2.amazonaws.com/bn-logo.png";
export const BN_URL = "https://www.bettingnews.com";

export const PAGES_PER_ARCHIVE_PAGE = 10;
export const POSTS_PER_ARCHIVE_PAGE = 10;
export const VISIBLE_PAGES_PER_ARCHIVE_PAGE = 5;

export const SOCIAL_LINKS = {
  twitter: {
    color: "#000000",
    type: "twitter",
    url: "https://twitter.com/YourBettingNews",
  },
  twitch: {
    color: "#9147FF",
    type: "twitch",
    url: "https://www.twitch.tv/bettingnews",
  },
  tiktok: {
    color: "#000000",
    type: "tiktok",
    url: "https://www.tiktok.com/@yourbettingnews",
  },
  spotify: {
    color: "#1DB954",
    type: "spotify",
    url: "https://open.spotify.com/show/05w8cplDU3yAvdWPm0AUm6",
  },
  facebook: {
    color: "#4267B2",
    type: "facebook",
    url: "https://www.facebook.com/yourbettingnews",
  },
  instagram: {
    color: "#FFF",
    type: "instagram",
    url: "https://www.instagram.com/betting.news",
  },
  youtube: {
    color: "#FF0000",
    type: "youtube",
    url: "https://www.youtube.com/@YourBettingNews",
  },
  discord: {
    color: "#5865F2",
    type: "discord",
    url: "https://discord.gg/kvkEegKfxc",
  },
};

export const SOCIAL_GOOGLE = {
  google: {
    color: "#000000",
    type: "google",
    url: "https://news.google.com/publications/CAAqBwgKMPXmpAswuPG8Aw",
  },
};

export const PRIVACY_POLICY_PATH = "/privacy-policy/";

export const SITE_LOGOS_SMALL = {
  DraftKings:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/square/draftkingsSmall.png",
  FanDuel:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/square/fanduelSmall.png",
  Sugarhouse:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/square/betriversSmall.png",
  BetRivers:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/square/betriversSmall.png",
  BetMGM:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/square/betmgmSmall.png",
  Unibet:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/square/unibetSmall.png",
  PointsBet:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/square/pointsbetSmall.png",
  WilliamHill:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/square/williamhillSmall.png",
  Caesars:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/square/williamhillSmall.png",
  Bet365:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/square/bet365Small.png",
  mybookieag:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/mybookie-logo-24x24.webp",
  betus:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/betus-logo-24x24.webp",
  bovada:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/bovada-logo-24x24.webp",
  betonlineag:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/betonline-logo-24x24.webp",
  everygame:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/everygame-logo-194x62.webp",
};

export const SITE_LOGOS_FULL = {
  DraftKings:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/full/draftkings.png",
  FanDuel:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/full/fanduel.png",
  Sugarhouse:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/full/betrivers.png",
  BetRivers:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/full/betrivers.png",
  BetMGM:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/full/betmgm.png",
  Unibet:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/full/unibet.png",
  PointsBet:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/full/pointsbet.png",
  WilliamHill:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/full/williamhill.png",
  Caesars:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/full/williamhill.png",
  Bet365:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/full/bet365.png",
  mybookieag:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/mybookie-logo-194x62.webp",
  betus:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/betus-logo-194x62.webp",
  bovada:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/bovada-logo-194x62.webp",
  betonlineag:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/betonline-logo-194x62.webp",
  everygame:
    "https://fantasydataobj.s3.us-east-2.amazonaws.com/books/everygame-logo-194x62.webp",
};

export const affiliateLinks = {
  mybookieag: "https://www.bettingnews.com/register/mybookie/",
  betus: "https://www.bettingnews.com/register/betus/",
  bovada: "https://www.bettingnews.com/register/bovada/",
  betonlineag: "https://www.bettingnews.com/register/betonline/",
};

export const GAME_STATUS_FILTER = [
  "Final",
  "Full Time",
  "Postponed",
  "Canceled",
  "F",
  "FT",
  "Final Score - After Penalties",
];

export const NEWS_CATEGORIES = [
  "sports-betting-shows",
  "nfl",
  "nba",
  "wnba",
  "mlb",
  "nhl",
  "ncaaf",
  "ncaab",
  "f1",
  "nascar",
  "horses",
  "mma",
  "boxing",
  "golf",
  "soccer",
  "tennis",
  "miscellaneous-sports",
  "industry",
];

export const RESTRICTED_AFFILIATES_STATES = {
  BetOnline: ["New Jersey"],
  Bovada: [
    "Delaware",
    "Nevada",
    "Maryland",
    "New York",
    "New Jersey",
    "Colorado",
    "Connecticut",
    "Kansas",
    "Louisiana",
    "Michigan",
    "Ohio",
    "Pennsylvania",
    "West Virginia",
  ],
  MyBookie: ["New Jersey", "New York", "Pennsylvania", "Nevada"],
};

export const SENTRY_IGNORED_ERRORS = [
  /Failed to load static props/,
  /Load failed/,
  /Failed to fetch/,
  /Failed to load client build manifest/,
  /Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node./,
  /Cannot destructure property 'address' of '(intermediate value)' as it is undefined./,
  /Invariant: attempted to hard navigate to the same URL/,
  /_error.js called with falsy error/,
  /falsy error/,
  /Hydration failed/,
  /Hydration failed - the server rendered HTML didn't match the client./,
  /Hydration/,
  /hydration/,
  /hydrating/,
  /Hydrating/,
  /server-rendered/,
  /Blocked a frame with origin/,
  /blocked a frame with origin/,
  /Error serializing `.page` returned from `getStaticProps/,
  /Error serializing/,
  /error serializing/,
  /Module build failed/,
  /module build failed/,
  /Can't find variable: gmo/,
  /Unhandled error response received for message <page-ready>/,
  /cancelled/,
  /Invalid call to runtime.sendMessage(). Tab not found./,
  /NetworkError when attempting to fetch resource./,
  /Cannot read properties of null (reading 'removeChild')/,
  /Java bridge method invocation error/,
  /Minified React error #418/, // Hydration Failed Error
  /Minified React error #425/, // Text content did not match server-rendered HTML
];
