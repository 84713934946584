export function formatBanners(data) {
  const edges = data?.banners?.edges || [];

  return edges.map((edge) => {
    const banner = edge?.node;
    if (!banner) return {};

    const title = banner.title || "";
    const bannerInfo = banner?.bannerInfo || {};

    return {
      title,
      bannerInfo: {
        title: bannerInfo.title || "",
        src: bannerInfo.src || "",
        link: bannerInfo.link || "",
        active: bannerInfo.active || false,
        imageOrVideo: bannerInfo.imageorvideo || "",
        videoEmbed: bannerInfo.videoEmbed || "",
      },
    };
  });
}
